import React from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import CurrencyFormat from 'react-currency-format';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DebtForm from "../../components/debt/debtForm"
import DebtGraph from "../../components/debt/debtGraph"
import {isAuthenticated, login} from "../../utils/auth"

const DebtPage = ({debts, dispatch}) => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  let link = ""
  let minPayment = ''
  if (debts.total.interestIfOnlyMinPayment > 0) {
    link = <Link to="/user/debt-plan" className="btn btn-primary p-3 mt-5">CREATE YOUR DEBT PLAN</Link>
    minPayment = <div>
        <h3 className="mt-5">What happens when you only make the minimum payment?</h3>
        <p>
          You'll be paying <b><CurrencyFormat value={debts.total.interestIfOnlyMinPayment} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'£'} /></b> in interest
          over the lifetime of debt!
        </p>
      </div>
  }

  return (
    <Layout>
      <SEO title="Debt Calculator" />
      <h2>Focus on your non-priority debts</h2>
      <p>
      Once you’ve paid off your priority debts, you can now focus on busting your non-priority debts.
Non-priority doesn’t mean they aren’t important, it simply means the
consequences of non-payment aren’t quite as serious (but still not great…) This includes things like bank loans, credit cards and overdrafts. To start making a plan to pay off your debts, enter your non-priority debts here: 
      </p>
      <DebtForm></DebtForm>
      <div class="bg-warning p-3 mt-5">      
      Action point: It’s worth seeing if you can reduce the interest you’re paying. You can
do this with a balance transfer which is when you get a new credit card that pays off the
debts on your old cards. Either do this with a 0 per cent deal where there is no interest
for a set period (but there will be a fee), or it may make sense to go for a card with a
higher interest rate initially, but that works out cheaper in the long run. If you’re struggling to keep up with payments because of Covid-19, you could speak to your lender about taking a payment holiday.
      </div>
      {minPayment}
      <div className="text-center">
        { link }
      </div>

      {debts.details.map(debt => (
        <DebtGraph debt={debt} key={debt.id} />
      ))}     

    </Layout>
  )
}

export default connect(state => ({ debts: state.debt.debts }))(DebtPage)
